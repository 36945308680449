import { template as template_843f5ce09a6b41cebcf1b7e5c0e1e2bf } from "@ember/template-compiler";
const FKControlMenuContainer = template_843f5ce09a6b41cebcf1b7e5c0e1e2bf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
