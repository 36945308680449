import { template as template_5f49491442e943929f27b07670ac1126 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5f49491442e943929f27b07670ac1126(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
